import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use radio buttons where users must make a `}<strong parentName="p">{`single`}</strong>{` selection from a list of
options.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h3 {...{
      "id": "label",
      "style": {
        "position": "relative"
      }
    }}>{`Label`}</h3>
    <p>{`Radio buttons should always be accompanied by a label for a correct semantic
markup. This makes the radio accessibile for the broadest range of users.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1052%3A0" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "helper-message",
      "style": {
        "position": "relative"
      }
    }}>{`Helper message`}</h3>
    <p>{`An inline helper message can be added to aid in use.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1052%3A69" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <h3 {...{
      "id": "label-groups-of-radio-buttons",
      "style": {
        "position": "relative"
      }
    }}>{`Label groups of radio buttons`}</h3>
    <p>{`A set of related radio buttons should always be grouped using
`}<a parentName="p" {...{
        "href": "/components/fieldset/code"
      }}>{`Fieldset`}</a>{`. The `}<inlineCode parentName="p">{`Fieldset`}</inlineCode>{` element ties related radio
buttons together in a way that is accessible to people who cannot see the visual
layout and rely on assistive technology.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1052%3A117" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "text-overflow",
      "style": {
        "position": "relative"
      }
    }}>{`Text overflow`}</h3>
    <p>{`If the label text is too long for the available space, it wraps to another line.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=1067%3A32" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "should-i-use-a-checkbox-instead-of-radio-buttons",
      "style": {
        "position": "relative"
      }
    }}>{`Should I use a Checkbox instead of Radio Buttons?`}</h2>
    <p>{`When users have to choose from two mutually exclusive options, you could use a
single `}<a parentName="p" {...{
        "href": "/components/checkbox/code"
      }}>{`Checkbox`}</a>{` instead of radio buttons.`}</p>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`To create a simple list of option where the user can make `}<strong parentName="li">{`multiple`}</strong>{`
selections, use the `}<a parentName="li" {...{
          "href": "/components/checkbox/code"
        }}>{`Checkbox`}</a>{` or
`}<a parentName="li" {...{
          "href": "/components/choice/code"
        }}>{`Checkbox Choice`}</a>{` component.`}</li>
      <li parentName="ul">{`When a user needs to make a binary choice (on/off), use the
`}<a parentName="li" {...{
          "href": "/components/switch/code"
        }}>{`Switch`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      